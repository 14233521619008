<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item prop="product_name">
            <el-input v-model="ruleForm.product_name">
              <template slot="prepend">商品名</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="category1">
            <el-autocomplete
              popper-class="my-autocomplete"
              v-model="ruleForm.category1"
              :fetch-suggestions="querySearch"
              placeholder="カテゴリー"
              style="width: 100%"
              @select="handleSelect"
            >
              <i
                class="el-icon-edit el-input__icon"
                slot="suffix"
                @click="handleIconClick"
              >
              </i>
              <template slot-scope="{ item }">
                <div class="name">
                  {{ (item.value = item.category_name) }}
                </div>
                <span class="addr">親：{{ item.parent_id }}</span>
              </template>
              <template slot="prepend">カテゴリー</template>
            </el-autocomplete>
          </el-form-item>
        </el-col>
      </el-row>
      <!--      <el-row :gutter="20">-->
      <!--        <el-col :span="8">-->
      <!--          <el-form-item prop="category1I">-->
      <!--            <el-input v-model="ruleForm.category1I">-->
      <!--              <template slot="prepend">大カテゴリ</template>-->
      <!--            </el-input>-->
      <!--          </el-form-item>-->
      <!--        </el-col>-->
      <!--        <el-col :span="8">-->
      <!--          <el-form-item prop="category1II">-->
      <!--            <el-input v-model="ruleForm.category1II">-->
      <!--              <template slot="prepend">小カテゴリ</template>-->
      <!--            </el-input>-->
      <!--          </el-form-item>-->
      <!--        </el-col>-->
      <!--      </el-row>-->
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item prop="price">
            <el-input v-model="ruleForm.price">
              <template slot="prepend">価格</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item>
            <el-button-group>
              <el-button
                type="warning"
                @click="cancelForm()"
                icon="el-icon-document-delete"
                plain
                >閉じる</el-button
              >
              <el-button
                type="primary"
                @click="submitForm('ruleForm')"
                icon="el-icon-document-checked"
                plain
                >登録・更新</el-button
              >
            </el-button-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {
  postProductData,
  putProductData,
  getProductDataOne,
} from "api/products";
import { getCategoryDataAll } from "api/category";
import { Message } from "element-ui";
// import PageHeader from 'components/common/menu/PageHeader'

export default {
  name: "ProductForm",
  components: {
    // PageHeader
  },
  props: {
    productId: {
      type: String, //指定传入的类型
      //type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: "", //这样可以指定默认的值
    },
  },
  data() {
    let checkCategory = (rule, value, callback) => {
      let findCategory = true;
      for (let category of this.Categories) {
        if (category.category_name === value) {
          findCategory = true;
          break;
        } else {
          findCategory = false;
        }
      }
      if (!findCategory) {
        callback(new Error("カテゴリーはマスターに存在しません。"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        product_name: "",
        category1: "",
        price: "",
      },
      Categories: [],
      rules: {
        product_name: [
          {
            required: true,
            message: "商品名を入力してください",
            trigger: "blur",
          },
        ],
        category1: [
          { validator: checkCategory, trigger: ["blur", "change"] },
          {
            required: true,
            message: "カテゴリーを入力してください",
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            message: "金額を入力してください",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created: function () {
    if (this.productId) {
      this.getProductDataOne();
    }
    this.getCategoryDataAll();
  },
  methods: {
    postProductData() {
      let values = {};
      values["product_name"] = this.ruleForm.product_name;
      values["category1"] = this.ruleForm.category1;
      values["category2"] = this.ruleForm.category2;
      values["category3"] = this.ruleForm.category3;
      values["price"] = this.ruleForm.price;
      postProductData(values)
        .then((res) => {
          if (res.status == 200) {
            this.$emit("submit");
            Message({
              showClose: true,
              message: res.data.message,
              type: "success",
            });
          } else {
            this.$emit("cancel");
            Message({
              showClose: true,
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    putProductData() {
      let values = {};
      values["product_name"] = this.ruleForm.product_name;
      values["category1"] = this.ruleForm.category1;
      values["category2"] = this.ruleForm.category2;
      values["category3"] = this.ruleForm.category3;
      values["price"] = this.ruleForm.price;
      values["id"] = this.productId;
      putProductData(values)
        .then((res) => {
          if (res.status == 200) {
            this.$emit("submit");
            Message({
              showClose: true,
              message: res.data.message,
              type: "success",
            });
          } else {
            this.$emit("cancel");
            Message({
              showClose: true,
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProductDataOne() {
      let values = {};
      values["id"] = this.productId;
      getProductDataOne(values)
        .then((res) => {
          this.ruleForm.product_name = res.data.data[0].product_name;
          this.ruleForm.category1 = res.data.data[0].category1;
          this.ruleForm.category2 = res.data.data[0].category2;
          this.ruleForm.price = res.data.data[0].price;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCategoryDataAll() {
      getCategoryDataAll()
        .then((res) => {
          this.Categories = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.productId) {
            this.putProductData();
          } else {
            this.postProductData();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    querySearch(queryString, cb) {
      let Categories = this.Categories;
      let results = queryString
        ? Categories.filter(this.createFilter(queryString))
        : Categories;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.category_name
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) > -1
        );
      };
    },
    handleSelect(item) {
      this.ruleForm.category2 = item.parent_id;
      // this.ruleForm.parent_name = item.category_name
      // this.ruleForm.Personnel = item.Personnel
    },
    handleIconClick(ev) {
      console.log(ev);
    },
    cancelForm() {
      this.$emit("cancel");
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
.name {
  text-overflow: ellipsis;
  overflow: hidden;
}
.addr {
  font-size: 12px;
  color: #b4b4b4;
}

.highlighted .addr {
  color: #ddd;
}
</style>
