import {request} from 'network/request'

export function postCategoryData(paramenter) {
  return request({
    url: '/category',
    method: 'post',
    data: paramenter
  })
}

export function putCategoryData(paramenter) {
  return request({
    url: '/category',
    method: 'put',
    data: paramenter
  })
}

export function getCategoryDataAll(paramenter) {
  return request({
    url: '/category',
    method: 'get',
    data: paramenter
  })
}

export function getCategoryDataParent(paramenter) {
  return request({
    url: '/category/parent',
    method: 'get',
    data: paramenter
  })
}

export function getCategoryDataOne(paramenter) {
  return request({
    url: '/category/' + paramenter.id,
    method: 'get',
    data: paramenter
  })
}

export function searchCategoryData(parameter) {
  return request({
    url: '/SearchCategoryData',
    method: 'post',
    data: parameter
  })
}

export function deleteCategory(paramenter) {
  return request({
    url: '/category/' + paramenter.id,
    method: 'delete',
    data: paramenter
  })
}
